import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  appLocalesLanguageList = {
    'en-US': '(en-US) English (United States)',
    'de-DE': '(de-DE) Deutsch (Deutschland)',
    'es-ES': '(es-ES) español (España)',
    'it-IT': '(it-IT) italiano (Italia)',
    'nb-NO': '(nb-NO) Norsk bokmål (Norge)',
    'nl-NL': '(nl-NL) Nederlands (Nederland)',
    'sv-SV': '(sv-SV) Swedish (Swedish)',
    'da-DA': '(da-DA) Danish (Danish)',
    'fr-FR': '(fr-FR) français (France)',
    'el-GR': '(el-GR) Ελληνικά (Ελλάδα)',
    'cs-CZ': '(cs-CZ) čeština (Česká republika)',
    'hu-HU': '(hu-HU) magyar (Magyarország)',
    'pl-PL': '(pl-PL) polski (Polska)',
    'pt-PT': '(pt-PT) português (Portugal)',
    'sl-SL': '(sl-SL) Slovenščina',
    'sk-SK': '(sk-SK) Slovenčina',
    'tr-TR': '(tr-TR) Türkçe (Türkiye)',
    'pt-BR': '(pt-BR) português (Brasil)',
  };

  appRegionsDescription = [
    {
      key: 'US/Pacific-PST',
      Label: 'United States (PST)',
      zone: 'PST',
    },
    {
      key: 'Europe/Berlin-CET',
      Label: 'Germany (CET)',
      zone: 'CET',
    },
    {
      key: 'Europe/Madrid-CET',
      Label: ' Spain (CET)',
      zone: 'CET',
    },
    {
      key: 'Europe/Rome-CET',
      Label: 'Italy (CET)',
      zone: 'CET',
    },
    {
      key: 'Europe/Oslo-CET',
      Label: 'Norway (CET)',
      zone: 'CET',
    },
    {
      key: 'Europe/Amsterdam-CET',
      Label: 'Netherland (CET)',
      zone: 'CET',
    },
    {
      key: 'Europe/Stockholm-CET',
      Label: 'Sweden (CET)',
      zone: 'CET',
    },
    {
      key: 'Europe/Copenhagen-CET',
      Label: 'Denmark (CET)',
      zone: 'CET',
    },
    {
      key: 'Europe/Paris-CET',
      Label: 'France (CET)',
      zone: 'CET',
    },
    {
      key: 'Europe/Athens-EET',
      Label: 'Greece (EET)',
      zone: 'EET',
    },
    {
      key: 'Europe/Prague-CET',
      Label: 'Czech Republic (CET)',
      zone: 'CET',
    },
    {
      key: 'Europe/Budapest-CET',
      Label: 'Hungary (CET)',
      zone: 'CET',
    },
    {
      key: 'Europe/Warsaw-CET',
      Label: 'Poland (CET)',
      zone: 'CET',
    },
    {
      key: 'Europe/Lisbon-GMT',
      Label: 'Portugal (GMT)',
      zone: 'GMT',
    },
    {
      key: 'Europe/Ljubljana-CET',
      Label: 'Slovenia (CET)',
      zone: 'CET',
    },
    {
      key: 'Europe/Bratislava-CET',
      Label: 'Slovakia (CET)',
      zone: 'CET',
    },
    {
      key: 'Turkey-TRT',
      Label: 'Turkey (TRT)',
      zone: 'TRT',
    },
    {
      key: 'Brazil/East-BRT',
      Label: 'Brazil (BRT)',
      zone: 'BRT',
    },
    {
      key: 'Europe/London-GMT',
      Label: 'Britain (GMT)',
      zone: 'GMT',
    },
    {
      key: 'Europe/Moscow-MST',
      Label: 'Россия (MST)',
      zone: 'MST',
    },
  ];

  getLanguage(): string {
    return (sessionStorage.getItem('lang') as string)
      ? (sessionStorage.getItem('lang') as string)
      : 'en-US';
  }
  setLanguage(language: string) {
    sessionStorage.setItem('lang', language);
  }

  getRegion(): string {
    return sessionStorage.getItem('region' as string)
      ? (sessionStorage.getItem('region') as string)
      : 'US/Pacific-PST';
  }
  setRegion(Region: string) {
    sessionStorage.setItem('region', Region);
  }
}
